<script lang="ts">
	import DynamicPicture from "./Picture/DynamicPicture.svelte";
	import Button from "./Button.svelte";
	import { getBlogPostPath } from "../../../core/schema/paths/getBlogPostPath.js";
	import type { PublishedBlogPost } from "../../../core/schema/BlogPost.js";
	import { nbspify } from "../../../core/utils/nbspify.js";

	export let blogPost: PublishedBlogPost;
</script>

<a
	href="/{getBlogPostPath(blogPost)}"
	class="group relative h-[29.938rem] w-full max-w-[27.5rem] cursor-pointer min-[425px]:h-[34.438rem] lg:h-[38.313rem] lg:w-[27.5rem]"
>
	<div
		class="right-0 top-0 h-[14.25rem] w-full overflow-hidden min-[425px]:h-[20.188rem] lg:absolute lg:h-[18.375rem] lg:w-[24.5rem]"
	>
		<DynamicPicture
			image={blogPost.primaryImage}
			alt=""
			bucket="storage"
			width={800}
			height={400}
			imgClass="h-full w-full pr-4 sm:w-full min-[425px]:pr-0 object-cover transition-all duration-200 group-hover:scale-110"
			class="h-full w-full"
		/>
	</div>
	<div class="absolute bottom-0 w-full pl-4 lg:right-auto lg:w-[24.5rem] lg:pl-0">
		<div
			class="border-gray flex h-[15.25rem] flex-col gap-2 border bg-white p-6 min-[425px]:h-[13.75rem] lg:h-[19.938rem]"
		>
			<p class="text-primary group-hover:text-tertiary text-base font-extrabold md:text-[1.5rem]">
				{nbspify(blogPost.title)}
			</p>
			<p class="text-2xs text-bito-black flex items-center gap-2 font-bold">
				{new Date(blogPost.publishedAt).toLocaleDateString()}
			</p>
			<p class="line-clamp-[6] text-xs text-gray-400 lg:line-clamp-[8]">{nbspify(blogPost.perex)}</p>
		</div>
		<Button variant="primary" class="w-[7.5rem] sm:w-[13.75rem]">Přečíst článek</Button>
	</div>
</a>
